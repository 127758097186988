<template>
  <div
    class="users-control"
  >
    <v-subheader 
      v-if="header"
      class="text-overline black--text pl-4 pr-2 py-1"
    >
      {{ typeof header == 'string' ? header : 'Pessoas' }}
      <v-spacer />
      <v-btn
        v-show="!input"
        text
        small
        plain
        :disabled="disabled"
        class="px-2"
        @click="toggleInput"
      >
        Adicionar
      </v-btn>
    </v-subheader>
    <!-- <v-divider v-if="header" /> -->
    <v-divider 
      v-show="input"
      class="mx-3"
    />
    <v-combobox
      v-show="input"
      v-model="temp"
      :search-input.sync="search"
      :items="options"
      multiple
      label="Adicionar pessoas"
      :placeholder="placeholder"
      solo
      flat
      hide-selected
      :hide-no-data="search==''||search==null"
      hide-details="auto"
      autocomplete="off"
      append-icon=""
      :disabled="disabled"
      ref="input"
      class="add-field py-1 pl-1"
      @blur="update"
      @change="update"
    >
      <template v-slot:selection="data">
        <v-chip
          :key="data.item"
          v-bind="data.attrs"
          :input-value="data.selected"
          :disabled="data.disabled"
          :color="isValid(data.item) ? 'primary' : 'error'"
          class="ml-0"
        >
          <v-tooltip 
            top
            :disabled="isValid(data.item)"
          >
            <template v-slot:activator="{ on, attrs }">
              <span 
                class="d-flex align-center"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon 
                  v-show="!isValid(data.item)"
                  left 
                  small
                >
                  {{ icons.alert }}
                </v-icon>
                {{ data.item }}
                <v-icon 
                  small
                  class="ml-2"
                  @click="data.parent.selectItem(data.item)"
                >
                  {{ icons.close }}
                </v-icon>
              </span>
            </template>
            Informe um e-mail válido
          </v-tooltip>
        </v-chip>
      </template>
      <!-- <template v-slot:append-outer>
        <v-btn
          v-show="search!=''&&search!=null"
          text
          small
          color="primary"
          @click.stop
        >
          Incluir
        </v-btn>
      </template> -->
      <template v-slot:item="data">
        <span 
          class="text-body-2"
          v-html="data.item"
        />
      </template>
      <template v-slot:no-data>
        <v-list 
          dense
          class="py-0 rounded"
        >
          <v-list-item
            class="pr-3"
            @click="useHint"
          >
            <v-list-item-content>
              <v-list-item-title class="text-body-2">
                <span 
                  class="font-weight-bold primary--text"
                >
                  {{ helper.text }}
                </span>
                <span 
                  class="text--disabled"
                >
                  {{ helper.hint }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
            <!-- <v-list-item-action>
              <v-btn
                text
                small
                color="primary"
              >
                Adicionar
              </v-btn>
            </v-list-item-action> -->
          </v-list-item>
        </v-list>
      </template>
    </v-combobox>
    <v-divider 
      v-show="empty" 
      class="mx-3"
    />
    <v-list
      v-if="empty"
    >
      <v-list-item
        v-for="user in list"
        :key="user.id"
        class="pr-3"
      >
        <v-list-item-content>
          <v-list-item-title 
            :title="user.name+' <'+user.email+'>'"
            class="text-body-2"
          >
            {{ user.name }} <span class="text--disabled">&lt;{{ user.email }}&gt;</span>
          </v-list-item-title>
        </v-list-item-content>
        <v-menu
          offset-y
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              plain
              small
              right
              :disabled="disabled"
              v-bind="attrs"
              v-on="on"
            >
              {{ user | profileTitle(profiles) }}
              <v-icon small right>{{ icons.menu }}</v-icon>
            </v-btn>
          </template>
          <!-- Options -->
          <v-list dense>
            <v-list-item
              v-for="(profile, pid) in profiles"
              :key="'profile-'+pid"
              :disabled="profile.id==user.profile"
              class="text-overline"
              @click="setProfile(user.id, profile.id)"
            >
              {{ profile.title }}
            </v-list-item>
            <v-divider class="my-2" />
            <v-list-item
              class="text-overline error--text"
              @click="remove(user.id)"
            >
              Remover
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
    </v-list>
  </div>
</template>

<style lang="scss">

.users-control .add-field input, .users-control .add-field label {
  font-size: 0.9375rem !important;
}

</style>

<script>

  import { 
    mdiClose,
    mdiAlertCircle,
    mdiMenuDown,
    mdiCheck
  } from '@mdi/js';

  export default {
    props: {
      users: {
        type: Array,
        default: () => []
      },
      suggestions: {
        type: Array,
        default: () => []
      },
      profiles: {
        type: Object,
        default: () => {}
      },
      disabled: {
        type: Boolean,
        default: false
      },
      header: {
        type: [Boolean, String],
        default: true
      }
    },
    
    data: () => ({
      icons: {
        close: mdiClose,
        alert: mdiAlertCircle,
        menu: mdiMenuDown,
        check: mdiCheck
      },
      input: false,
      controller: {},
      temp: [],
      search: '',
      searched: '',
      rules: [v => v!=null && v!='' && /.+@.+\..+/.test(v) || 'Campo obrigatório'],
      placeholder: 'Nome Sobrenome <email@corp.com>'
    }),

    computed: {
      list () {
        return _.orderBy(_.values(this.controller), ['id'], ['desc']);
      },
      helper () {
        let [name, surname, email] = _.split(this.placeholder, ' ');
        const input = _.trim(this.search);
        const parsed = this.parse(input);
        const fullname = _.split(parsed.name, ' ');
        let text = [];
        let hint = [];
        if (fullname[0]!=''&&!_.isNil(parsed.name)) {
          name = fullname[0].indexOf('@')>=0 ? _.split(fullname[0], '@')[0] : fullname[0]
          text.push(name);
        }else{
          hint.push(name);
        }
        if (fullname.length>1&&_.split(fullname[1],'<').length<=1) {
          if (fullname.length>1) {
            surname = fullname[1]!='' ? fullname[1] : surname
            text.push(surname);
          }else{
            hint.push(surname);
          }
        }
        if (fullname.length>1&&_.split(parsed.name,'<').length>1) {
          text.push(`<${_.split(parsed.name,'<')[1]}>`);
        }else if (parsed.email!='') {
          text.push(`<${parsed.email}>`);
        }else{
          const suggestion = text.length==0 ? null : text.length==1 ? name.toLocaleLowerCase() : name.toLocaleLowerCase() + '.' + surname.toLocaleLowerCase();
          email = suggestion==null||name.indexOf('@')>=0 ? email : '<'+_.replace(email, _.split(email, '@')[0], suggestion)
          hint.push(email);
        }
        text = _.join(text, ' ')
        hint = _.join(hint, ' ')
        const value = _.join([text, hint], ' ');
        console.log(text, hint);
        return { text, hint, value };
      },
      options () {
        const options = _.map(_.filter(this.suggestions, user => {
          return _.isNil(_.find(this.controller, ['email', user.email]))
        }), user => {
          return `${user.name} <${user.email}>`
        })
        return options;
      },
      validated () {
        const data = _.clone(this.temp);
        return _.every(data, (data, f) => {
          return this.checkRules(this.rules, data);
        });
      },
      empty () {
        return _.size(this.users)>0;
      },
      hasMultipleProfiles () {
        return _.size(this.profiles)>1;
      }
    },

    watch: {
      users: {
        immediate: true,
        deep: true,
        handler (users) {
          console.log('users', users);
          this.controller = Object.assign({}, _.keyBy(_.clone(users), 'id'));
          const hasEmail = _.find(_.orderBy(users, ['id'], ['desc']), u => u.email.indexOf('@')>=0)
          if (!_.isNil(hasEmail)) {
            const placeholder = _.replace(this.placeholder, _.split(this.parse(this.placeholder).email, '@')[1], _.split(hasEmail.email, '@')[1])
            this.placeholder = placeholder;
          }
          // this.temp = _.map(_.clone(temp), user => {
          //   return `${user.name} ` + (user.email=='' ? '' : `<${user.email}>`)
          // })
        }
      },
    },

    methods: {
      toggleInput (b) {
        this.input = true;
        this.$nextTick(() => {
          this.$refs['input'].focus();
        })
      },

      useHint () {
        console.log('useHint', this.helper.value.toString());
        this.search = this.helper.value.toString();
        this.temp = [this.search.toString()];
        this.update();
      },

      setProfile (id, profile) {
        this.controller[id].profile = profile;
        this.update();
      },

      update () {
        if (_.size(this.temp)==0&&_.size(this.search)>0) {
          this.temp.push(this.search);
          this.search = '';
        }
        const parsed = _.reject(_.clone(this.parseUsers()), user => {
          const duplicated = !_.isNil(_.find(this.controller, ['email', user.email]));
          return duplicated;
        });
        const users = _.concat(_.values(this.controller), parsed);
        console.log('update users', users);
        this.$emit('update', users);
      },

      remove (id) {
        console.log('remove user', id);
        setTimeout(($,id) => {
          if (_.has($.controller, id)) {
            $.$delete($.controller, id);
          }
          this.update();
        }, 250, this, id);
      },

      parseUsers () {
        const users = _.reduce(_.clone(this.temp), (list, user, i) => {
          const u = this.parse(user, i);
          const status = _.has(u, 'error') ? 'invalid' : 'valid';
          list[status].push(u);
          return list;
        }, { valid: [], invalid: [] });
        this.temp = _.map(users.invalid, 'error');
        return users.valid;
      },

      parse (user, i) {
        i = _.isNil(i) ? 0 : i;
        const parsed = /(?:"?([^"]*)"?\s)?(?:<?(.+@[^>]+)>?)/g.exec(user);
        let [ combo, name = '', email = '' ] = _.isNil(parsed) ? [user, user, ''] : parsed;
        name = name == '' ? _.words(email)[0] : name;
        user = { name, email, profile: 7, id: Date.now()+i }
        if (_.isNil(parsed)) user.error = combo;
        return user
      },

      isValid (user) {
        return this.checkRules(this.rules, user);
      },
      checkRules (rules, value) {
        return _.every(rules, rule => rule(value)==true);
      },
    },

    filters: {
      format (user) {
        let text = 'Nome <email@corp.com>'
        if (!_.isNil(user)&&!_.isEmpty(user)) {
          const r = /(?:"?([^"]*)"?\s)?(?:<?(.+@[^>]+)>?)/g;
          const parsed = r.exec(user);
          let [ combo, name = '', email = '' ] = _.isNil(parsed) ? [] : parsed;
          name = name == '' ? _.words(email)[0] : name;
          text = `${name} <${email}>`
        }
        return text;
      },
      profileTitle (user, profiles) {
        const pid = user.profile;
        return _.has(profiles, pid) ? profiles[pid].title : '';
      }
    }
  }
</script>